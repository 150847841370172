import { Provider } from 'jotai'
import '../styles/globals.css'
import { FC } from 'react'
import { AppProps } from 'next/app'

const App: FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <Provider>
      <Component {...pageProps} />
    </Provider>
  )
}
export default App